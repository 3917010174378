import { HIDE_NAVBAR, SHOW_NAVBAR, START_LOADER, STOP_LOADER } from "../types/loader";

const initState = {
  isLoading: false,
  hideNavBar: false,
};

const reducer = (state = initState, action: { type: string }) => {
  switch (action.type) {
    case STOP_LOADER:
      return {
        ...state,
        isLoading: false,
      };
    case START_LOADER:
      return {
        ...state,
        isLoading: true,
      };
    case SHOW_NAVBAR:
      return {
        ...state,
        hideNavBar: false,
      };
    case HIDE_NAVBAR:
      return {
        ...state,
        hideNavBar: true,
      };
    default:
      return state;
  }
};

export default reducer;
