// ErrorBoundary.js
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SET_INITIAL_DATA } from '../../../store/types/movies';
import "./index.css"

const ErrorBoundary = ({ children }: any) => {
  const dispatch = useDispatch();

  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const handleError: any = (error: any, errorInfo: any) => {
      console.error(error, errorInfo);
      setHasError(true);
    };

    window.addEventListener('error', handleError);

    return () => {
      window.removeEventListener('error', handleError);
    };
  }, []); // Empty dependency array means this effect runs once when the component mounts

  const handleReset = () => {
    setHasError(false);
    console.log("Resetting Data")
    dispatch({ type: SET_INITIAL_DATA });
    setTimeout(() => window.location.href = '/', 3000)
  };

  if (hasError) {
    return (
      <div className='flex-container'>
        <h2 className='inner-element'>Something went wrong.</h2>
        <button onClick={handleReset}>Reset</button>
      </div>
    );
  }

  return children;
};

export default ErrorBoundary;
